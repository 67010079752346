import {
  Button,
  buttonSizes,
  buttonTypes,
  HeaderExtraSmall
} from '@animoto/components';
import { trackFeatureUsage } from '@animoto/utils/analytics';
import PropTypes from 'prop-types';
import React from 'react';

import {
  container,
  desktop,
  mobile,
  text
} from './Banner.module.css';

export default function Banner({ onClick }) {
  const trackClick = () => {
    trackFeatureUsage({
      scope   : 'visitor-play-page',
      feature : 'banner',
      action  : 'click'
    });
  };

  const onClickButton = () => {
    trackClick();
    onClick();
  };

  const getMobileBanner = () => (
    <div className={mobile}>
      <Button
        onClick={onClickButton}
        size={buttonSizes.SMALL}
        type={buttonTypes.SECONDARY}
      >
        Start free
      </Button>
    </div>
  );

  const getDesktopBanner = () => (
    <div className={desktop}>
      <HeaderExtraSmall className={text}>Create a video in minutes with Animoto.<br /> No credit card required. </HeaderExtraSmall>
      <Button
        onClick={onClickButton}
        size={buttonSizes.SMALL}
        type={buttonTypes.SECONDARY}
      >
        Get started free
      </Button>
    </div>
  );

  return (
    <div className={container}>
      {getMobileBanner()}
      {getDesktopBanner()}
    </div>
  );
}

Banner.propTypes = { onClick : PropTypes.func.isRequired };
