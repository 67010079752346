import {
  Button,
  HeaderExtraSmall,
  ToastPanel,
  toastPanelAlignments
} from '@animoto/components';
import { trackFeatureUsage } from '@animoto/utils/analytics';
import classNames from 'classnames';
import React from 'react';

import {
  button,
  content,
  text
} from './OwnerToast.module.css';

export default function OwnerToast() {
  const onClickButton = () => {
    trackFeatureUsage({
      scope   : 'visitor-play-page',
      feature : 'owner-share-toast',
      action  : 'click:share-page'
    });
  };

  const buttonClasses = classNames('s-toast-button', button);
  return (
    <ToastPanel
      alignment={toastPanelAlignments.RIGHT}
      isDismissable={false}
      isOpen
    >
      <div className={content}>
        <HeaderExtraSmall className={text}>This is the video page your viewers will see.</HeaderExtraSmall>
        <Button
          className={buttonClasses}
          href={`/share/${window.location.href.split('/').pop()}`}
          onClick={onClickButton}
        >
          Share video
        </Button>
      </div>
    </ToastPanel>
  );
}
