import {
  BodySmall,
  ControlButton,
  HeaderSmall,
  iconSizes,
  Link,
  ShareIcon
} from '@animoto/components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  buttonWrapper,
  details,
  divider,
  link,
  videoTitle,
  wrapper
} from './VideoDetails.module.css';

export default function VideoDetails({
  description = '',
  displayDate = '',
  isSharingEnabled,
  onOpenShareModal,
  producerName = '',
  title
}) {
  const isMobile = useMediaQuery({ maxWidth : 479 });
  const maxLength = isMobile ? 80 : 160;
  const showFullDescription = description.length <= maxLength;
  const [isTruncated, setIsTruncated] = useState(!showFullDescription);
  const linkText = isTruncated ? 'Show more' : 'Show less';

  let shownDescription;

  if (!isTruncated) {
    shownDescription = description;
  } else {
    const trimmedStr = !isTruncated ? description : description.slice(0, maxLength + 1);
    shownDescription = trimmedStr.substr(0, Math.min(trimmedStr.length, trimmedStr.lastIndexOf(' ')));
  }

  const onHandleClick = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div className={wrapper} id="details">
      <div>
        <HeaderSmall className={videoTitle}>{title}</HeaderSmall>
        <div className={details}>
          {producerName && (
            <BodySmall className={divider}>{producerName}</BodySmall>
          )}
          {displayDate && (
            <BodySmall>{displayDate}</BodySmall>
          )}
        </div>
        {description && (
          <>
            <BodySmall>{shownDescription}</BodySmall>
            {!showFullDescription && (
              <Link className={link} href="#details" onClick={onHandleClick} size="small">{linkText}</Link>
            )}
          </>
        )}
      </div>
      <div className={buttonWrapper}>
        <ControlButton
          className="s-sharing-button"
          isDisabled={!isSharingEnabled}
          isGhost
          onClick={onOpenShareModal}
        >
          <ShareIcon size={iconSizes.MEDIUM} />
          Share
        </ControlButton>
      </div>
    </div>
  );
}

VideoDetails.propTypes = {
  description      : PropTypes.string,
  displayDate      : PropTypes.string,
  isSharingEnabled : PropTypes.bool.isRequired,
  onOpenShareModal : PropTypes.func.isRequired,
  producerName     : PropTypes.string,
  title            : PropTypes.string.isRequired
};
