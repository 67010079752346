import { HeaderExtraSmall, HeaderMedium } from '@animoto/components';
import { trackFeatureUsage } from '@animoto/utils/analytics';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import animotoPlansPricing from './assets/animoto-plans-pricing.png';
import gettingStarted from './assets/getting-started.png';
import workLife from './assets/work-life.jpg';
import {
  blog,
  blogImage,
  blogsWrapper,
  blogTitle,
  blogWrapper,
  header,
  headerWrap,
  wrap
} from './BlogPosts.module.css';

const blogs = [
  {
    image : gettingStarted,
    style : 'getting-started',
    title : 'Complete Guide to Getting Started with Animoto',
    url   : '/blog/guides/getting-started-animoto'
  },
  {
    image : workLife,
    style : 'work-and-life',
    title : '10 Most Popular Video Templates for Work and Life',
    url   : '/blog/video-ideas/most-popular-video-templates-work-life'
  },
  {
    image : animotoPlansPricing,
    style : 'animoto-plans-pricing',
    title : 'Quiz: Find the Right Animoto Plan for You',
    url   : '/blog/news/animoto-news/animoto-plans-pricing'
  }
];
export default function BlogPosts() {
  // TODO: refactor to not use useMediaQuery
  const isMiddleSize = useMediaQuery({ minWidth : 480, maxWidth : 992 });

  const onClickBlog = (url) => {
    trackFeatureUsage({
      scope   : 'visitor-play-page',
      feature : 'Blog-Posts',
      action  : `click:${url}`
    });
  };

  const generateBlogs = () => blogs.map(({
    url,
    style,
    title,
    image
  }) => (
    <div key={style} className={blogWrapper}>
      <a className={blog} href={url} onClick={() => onClickBlog(url)} rel="noopener noreferrer" target="_blank">
        <img
          alt={style}
          className={blogImage}
          height={isMiddleSize ? '99' : '72'}
          src={image}
          value={url}
          width={isMiddleSize ? '148' : '108'}
        />
      </a>
      <a className={blog} href={url} onClick={() => onClickBlog(url)} rel="noopener noreferrer" target="_blank">
        <HeaderExtraSmall
          className={blogTitle}
          value={url}
        >{title}
        </HeaderExtraSmall>
      </a>
    </div>
  ));

  return (
    <div className={wrap}>
      <div className={headerWrap}>
        <HeaderMedium className={header}>Featured Blog Posts</HeaderMedium>
      </div>
      <div className={blogsWrapper}>
        {generateBlogs()}
      </div>
    </div>
  );
}
