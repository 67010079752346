import {
  BodySmall,
  Button,
  HeaderExtraSmall,
  HeaderMedium,
  HeaderSmall,
  TitleMedium,
  TitleSmall
} from '@animoto/components';
import { trackFeatureUsage } from '@animoto/utils/analytics';
import PropTypes from 'prop-types';
import React from 'react';

import {
  button,
  container,
  desktop,
  divider,
  header,
  mobile,
  mobileStepNumber,
  numberContainer,
  step,
  stepsContainer,
  stepText,
  title
} from './VideoSteps.module.css';

const videoStepsCopy = [
  {
    stepTitle : 'Add photos and video clips',
    body      : 'Select a template or start from scratch. Then drag and drop photos and video clips right into your project.'
  },
  {
    stepTitle : 'Personalize your video',
    body      : 'Quickly adjust colors, fonts, music, and more to customize your video. Match your own style or brand with ease.'
  },
  {
    stepTitle : 'Share and impress',
    body      : 'Produce and share your video in moments. Wow friends and family or boost business sales and engagement.'
  }
];

export default function VideoSteps({ onClick }) {
  const trackClick = () => {
    trackFeatureUsage({
      scope   : 'visitor-play-page',
      feature : 'video-in-steps',
      action  : 'click'
    });
  };
  const onClickButton = () => {
    trackClick();
    onClick();
  };

  const getDesktopBody = () => (
    <div className={desktop}>
      <TitleMedium className={title}>Make a video in 3 easy steps</TitleMedium>
      <div className={stepsContainer}>
        { videoStepsCopy.map(({ stepTitle, body }, index) => (
          <div key={stepTitle} className={step}>
            <div className={numberContainer}>
              <HeaderMedium>{(index + 1)}</HeaderMedium>
            </div>
            <HeaderSmall className={header}>{stepTitle}</HeaderSmall>
            <BodySmall className={stepText}>{body}</BodySmall>
          </div>
        ))}
      </div>
    </div>
  );
  const getMobileBody = () => (
    <div className={mobile}>
      <TitleSmall className={title}>Make a video in 3 easy steps</TitleSmall>
      <div className={stepsContainer}>
        { videoStepsCopy.map(({ stepTitle }, index) => (
          <div key={stepTitle} className={step}>
            <div className={numberContainer}>
              <HeaderMedium className={mobileStepNumber}>{(index + 1)}</HeaderMedium>
            </div>
            <HeaderExtraSmall className={header}>{stepTitle}</HeaderExtraSmall>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <div className={divider} />
      <div className={container}>
        {getDesktopBody()}
        {getMobileBody()}
        <Button
          className={button}
          onClick={onClickButton}
        >
          Make a video
        </Button>
      </div>
    </>
  );
}

VideoSteps.propTypes = { onClick : PropTypes.func.isRequired };
